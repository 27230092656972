export default function TimelineForMobile({ steps }) {
  return (
    <div className="time-line-mobile">
      {steps.map((step, i) => (
        <div key={step.text} className="time-line-item">
          <div className={['time-line-item-round'].concat(i === steps.length - 1 ? ['time-line-item-round-last'] : []).join(' ')}>
            <span></span>
          </div>
          <div>
            <h6>{step.text}</h6>
            <p>{step.date}, 2024</p>
          </div>
        </div>
      ))}
    </div>
  )
}
