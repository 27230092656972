import './style.css'

const tracks = ['DeFi', 'General', 'Games']

export default function RewardsSection() {
  return (
    <div className="rewards" id="rewards">
      <p>Total Rewards</p>
      <h1>$ 10,000</h1>
      <div className="rewards-award-wrapper">
        {tracks.map((track, idx) => (
          <div key={track} className="rewards-award-item">
            <div className="rewards-award-item-headbox">
              <p>Track {idx + 1}</p>
              <p>{track}</p>
            </div>
          </div>
        ))}
        <div className="rewards-award-item">
          <div className="rewards-award-item-headbox" style={{ backgroundImage: 'none' }}>
            <p style={{ marginBottom: '6px' }}>Special Rewards</p>
            <p>Most Popular by Community</p>
          </div>
        </div>
      </div>
      {/* <div className="rewards-event-detail">
        <h2>Event Detail</h2>
        <ul>
          <li>For the initial review, you need to submit the code to your GitHub. The submitted content includes source code, design documents, and project introduction.</li>
          <li>Do not upload the Demo video to Github. You can add the link address to Readme.</li>
          <li>Do not upload project-related PowerPoints that are too large. You can add the link address to the Readme.</li>
        </ul>
      </div> */}
      <div className="rewards-submission-deadline">
        <div>
          OpenBuild has provided excellent Starknet resources on OpenBuild official website.
          &nbsp;<a href="https://openbuild.xyz/learn/courses?query=Starknet" target="_blank" rel="noreferrer">
            More
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
              <path d="M7.87524 0.4375V6.125C7.87524 6.24103 7.82915 6.35231 7.7471 6.43436C7.66506 6.51641 7.55378 6.5625 7.43774 6.5625C7.32171 6.5625 7.21043 6.51641 7.12838 6.43436C7.04634 6.35231 7.00024 6.24103 7.00024 6.125V1.49352C4.55831 3.93567 3.18921 5.30488 0.747275 7.74703C0.665182 7.82912 0.55384 7.87524 0.437743 7.87524C0.321646 7.87524 0.210305 7.82912 0.128212 7.74703C0.0461192 7.66494 0 7.5536 0 7.4375C0 7.3214 0.0461192 7.21006 0.128212 7.12797L6.38173 0.875H1.75024C1.63421 0.875 1.52293 0.828906 1.44088 0.746859C1.35884 0.664812 1.31274 0.553532 1.31274 0.4375C1.31274 0.321468 1.35884 0.210188 1.44088 0.128141C1.52293 0.0460937 1.63421 0 1.75024 0H7.43774C7.55378 0 7.66506 0.0460937 7.7471 0.128141C7.82915 0.210188 7.87524 0.321468 7.87524 0.4375Z" fill="#F30B61"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  )
}
