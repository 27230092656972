import './App.css';

import OverviewSection from './components/overview-section'
import RewardsSection from './components/rewards-section'
import JudgingSection from './components/judging-section'
import PartnersSection from './components/partners-section'
// import Introduction from './Introduction'
import Header from './Header'

import OpenBuildLogo from './assets/openbuild-inherit.svg'

function App() {
  return (
    <div className="App" id="app">
      <Header />
      <OverviewSection />
      {/* <Introduction /> */}
      <RewardsSection />
      <JudgingSection />
      <PartnersSection />
      {/* <div className="interested">
        <h1>Interested in a campaign? </h1>
        <h1>Form your dream team and join us</h1>
        <div></div>
        <p><strong>Nearly 25,000 pre-registered developers and designers</strong> have already claimed their OpenBuild Early Bird badges</p>
        <a href="Go To Team Page" target="_blank" rel="noreferrer">Go To Team Page</a>
      </div> */}
      <div className="footer">
        <div>
          <div className="footer-contact">
            <div>
              <h1>CONTACT</h1>
              <p>Email: build@openbuild.xyz</p>
            </div>
            <div>
              <a href="mailto:build@openbuild.xyz">
                <svg className="right-arrow" width="36" height="23" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_120_14680)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M17.8075 5.76256C13.9063 5.76256 12.2538 1.49999 12.1825 1.31249C12.1027 1.09753 11.9409 0.922989 11.7326 0.82695C11.5244 0.730912 11.2866 0.720994 11.0712 0.79979C10.8549 0.881934 10.68 1.04672 10.5846 1.25755C10.4893 1.46839 10.4813 1.70829 10.5625 1.92497C11.157 3.39134 12.0768 4.7036 13.2525 5.76256H0.858765C0.646307 5.78809 0.450607 5.89056 0.308609 6.05064C0.166611 6.21073 0.0881958 6.4171 0.0881958 6.63109C0.0881958 6.84507 0.166611 7.05175 0.308609 7.21184C0.450607 7.37192 0.646307 7.47439 0.858765 7.49992H13.2525C12.0768 8.55888 11.157 9.87114 10.5625 11.3375C10.5223 11.4442 10.5035 11.5577 10.5072 11.6717C10.5109 11.7856 10.5369 11.8977 10.584 12.0016C10.631 12.1055 10.698 12.1992 10.7812 12.2771C10.8644 12.3551 10.9621 12.4161 11.0688 12.4563C11.1755 12.4965 11.289 12.5152 11.403 12.5115C11.5169 12.5078 11.6291 12.4816 11.733 12.4346C11.8369 12.3876 11.9305 12.3207 12.0084 12.2375C12.0864 12.1543 12.1473 12.0567 12.1875 11.95C12.2037 11.9 13.885 7.49992 17.8125 7.49992C18.0249 7.47379 18.2204 7.3708 18.3619 7.21031C18.5034 7.04982 18.5812 6.84294 18.5806 6.62895C18.58 6.41496 18.501 6.20849 18.3585 6.04881C18.2161 5.88914 18.0201 5.78747 17.8075 5.76256Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_120_14680">
                    <rect width="18.6712" height="11.7625" fill="black" transform="translate(0 0.75)"/>
                  </clipPath>
                  </defs>
                </svg>
                <svg className="left-arrow" width="36" height="23" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_120_14680)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M17.8075 5.76256C13.9063 5.76256 12.2538 1.49999 12.1825 1.31249C12.1027 1.09753 11.9409 0.922989 11.7326 0.82695C11.5244 0.730912 11.2866 0.720994 11.0712 0.79979C10.8549 0.881934 10.68 1.04672 10.5846 1.25755C10.4893 1.46839 10.4813 1.70829 10.5625 1.92497C11.157 3.39134 12.0768 4.7036 13.2525 5.76256H0.858765C0.646307 5.78809 0.450607 5.89056 0.308609 6.05064C0.166611 6.21073 0.0881958 6.4171 0.0881958 6.63109C0.0881958 6.84507 0.166611 7.05175 0.308609 7.21184C0.450607 7.37192 0.646307 7.47439 0.858765 7.49992H13.2525C12.0768 8.55888 11.157 9.87114 10.5625 11.3375C10.5223 11.4442 10.5035 11.5577 10.5072 11.6717C10.5109 11.7856 10.5369 11.8977 10.584 12.0016C10.631 12.1055 10.698 12.1992 10.7812 12.2771C10.8644 12.3551 10.9621 12.4161 11.0688 12.4563C11.1755 12.4965 11.289 12.5152 11.403 12.5115C11.5169 12.5078 11.6291 12.4816 11.733 12.4346C11.8369 12.3876 11.9305 12.3207 12.0084 12.2375C12.0864 12.1543 12.1473 12.0567 12.1875 11.95C12.2037 11.9 13.885 7.49992 17.8125 7.49992C18.0249 7.47379 18.2204 7.3708 18.3619 7.21031C18.5034 7.04982 18.5812 6.84294 18.5806 6.62895C18.58 6.41496 18.501 6.20849 18.3585 6.04881C18.2161 5.88914 18.0201 5.78747 17.8075 5.76256Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_120_14680">
                    <rect width="18.6712" height="11.7625" fill="black" transform="translate(0 0.75)"/>
                  </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <div>
            <img src={OpenBuildLogo} alt="" />
            <p>
              <a href="https://twitter.com/OpenBuildxyz" className="got">
                Go Twitter
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_123_18824)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.9747 2.84501C9.09852 4.72122 6.25382 3.46597 6.12938 3.41006C5.98759 3.3451 5.82584 3.33897 5.67951 3.39293C5.53317 3.44689 5.41404 3.55648 5.34837 3.69795C5.28384 3.84148 5.27896 4.00486 5.3345 4.15211C5.39004 4.29936 5.50158 4.41857 5.64483 4.48373C6.63595 4.90303 7.70939 5.09179 8.78409 5.03564L2.82366 10.9961C2.73376 11.1105 2.68893 11.2539 2.69763 11.3992C2.70632 11.5445 2.76786 11.6814 2.87077 11.7843C2.97368 11.8873 3.11079 11.9489 3.25607 11.9576C3.40135 11.9663 3.54475 11.9215 3.6592 11.8316L9.61962 5.87118C9.56348 6.94588 9.75224 8.01932 10.1715 9.01043C10.2035 9.08109 10.2491 9.1447 10.3056 9.19774C10.3622 9.25079 10.4286 9.29213 10.5012 9.31949C10.5738 9.34684 10.6511 9.35967 10.7286 9.35717C10.8061 9.35467 10.8824 9.33697 10.9531 9.30499C11.0237 9.27302 11.0873 9.22743 11.1403 9.17085C11.1934 9.11427 11.2347 9.04771 11.2621 8.97514C11.2894 8.90257 11.3022 8.82538 11.2997 8.74787C11.2972 8.67036 11.2796 8.59416 11.2476 8.5235C11.2314 8.49164 9.92381 5.56699 11.8126 3.67817C11.9022 3.56346 11.9467 3.41993 11.9376 3.27467C11.9285 3.12942 11.8664 2.99251 11.7632 2.88989C11.66 2.78727 11.5227 2.72597 11.3774 2.7177C11.2321 2.70942 11.0889 2.75478 10.9747 2.84501Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_123_18824">
                    <rect width="12.6988" height="8" fill="white" transform="translate(0 8.99854) rotate(-45)"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </p>
            <p>
              <a href="mailto:build@openbuild.xyz" className="got open-email">
                Email: build@openbuild.xyz
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_123_18824)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.9747 2.84501C9.09852 4.72122 6.25382 3.46597 6.12938 3.41006C5.98759 3.3451 5.82584 3.33897 5.67951 3.39293C5.53317 3.44689 5.41404 3.55648 5.34837 3.69795C5.28384 3.84148 5.27896 4.00486 5.3345 4.15211C5.39004 4.29936 5.50158 4.41857 5.64483 4.48373C6.63595 4.90303 7.70939 5.09179 8.78409 5.03564L2.82366 10.9961C2.73376 11.1105 2.68893 11.2539 2.69763 11.3992C2.70632 11.5445 2.76786 11.6814 2.87077 11.7843C2.97368 11.8873 3.11079 11.9489 3.25607 11.9576C3.40135 11.9663 3.54475 11.9215 3.6592 11.8316L9.61962 5.87118C9.56348 6.94588 9.75224 8.01932 10.1715 9.01043C10.2035 9.08109 10.2491 9.1447 10.3056 9.19774C10.3622 9.25079 10.4286 9.29213 10.5012 9.31949C10.5738 9.34684 10.6511 9.35967 10.7286 9.35717C10.8061 9.35467 10.8824 9.33697 10.9531 9.30499C11.0237 9.27302 11.0873 9.22743 11.1403 9.17085C11.1934 9.11427 11.2347 9.04771 11.2621 8.97514C11.2894 8.90257 11.3022 8.82538 11.2997 8.74787C11.2972 8.67036 11.2796 8.59416 11.2476 8.5235C11.2314 8.49164 9.92381 5.56699 11.8126 3.67817C11.9022 3.56346 11.9467 3.41993 11.9376 3.27467C11.9285 3.12942 11.8664 2.99251 11.7632 2.88989C11.66 2.78727 11.5227 2.72597 11.3774 2.7177C11.2321 2.70942 11.0889 2.75478 10.9747 2.84501Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_123_18824">
                    <rect width="12.6988" height="8" fill="white" transform="translate(0 8.99854) rotate(-45)"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
