import './style.css'
import TimelineForMobile from "./TimelineForMobile"

const steps = [
  { text: 'Registration Open', date: 'September 10th' },
  { text: 'Submission Deadline', date: 'October 12th' },
  { text: 'Online Demo-Day', date: 'October 14th to 15th' },
  { text: 'Wrap-Up', date: 'Before October 20th' },
]

export default function Timeline({ current = -1 }) {
  return (
    <>
      <div className="time-line">
        {steps.map((step, i) => (
          <div key={step.text} className={['time-line-item'].concat(current === i ? ['active'] : []).join(' ')}>
            <h6>{step.text}</h6>
            <div className="time-line-item-round">
              <span></span>
            </div>
            <div>
              <p>{step.date}</p>
              <p>2024</p>
            </div>
            {i !== steps.length - 1 && <div className="line" />}
          </div>
        ))}
      </div>
      <TimelineForMobile steps={steps} />
    </>
  )
}
