import './style.css'

import upchain from './brand-logo-light/upchain.svg'
import chainlink from './brand-logo-light/chainlink.svg'
import theGraph from './brand-logo-light/the-graph.svg'
import starknetZh from './brand-logo-light/starknet-zh.svg'
import starknetAfrica from './brand-logo-light/starknet-africa.svg'
import starknetFrance from './brand-logo-light/starknet-france.svg'
import starknetAsia from './brand-logo-light/starknet-asia.svg'
import starknetEs from './brand-logo-light/starknet-es.svg'
import starknet from './brand-logo-light/starknet.svg'
import blockdev from './brand-logo-light/blockdev.svg'
import akindo from './brand-logo-light/akindo.svg'
import contributionDao from './brand-logo-light/contribution-dao.svg'
import shurikenLabs from './brand-logo-light/shuriken-labs.svg'
import coineasy from './brand-logo-light/coineasy.svg'
import anyaxis from './brand-logo-light/anyaxis.svg'
import theWeb3 from './brand-logo-light/the-web3.svg'
import esolLabs from './brand-logo-light/esol-labs.svg'
import arnagon from './brand-logo-light/arnagon.svg'
import degenHouse from './brand-logo-light/degen-house.svg'
import hackQuest from './brand-logo-light/hack-quest.svg'
import seedao from './brand-logo-light/seedao.svg'
import lxdao from './brand-logo-light/lxdao.svg'
import builderdao from './brand-logo-light/builderdao.svg'
import rebase from './brand-logo-light/rebase.svg'
import starknetAstro from './brand-logo-light/starknet-astro.svg'
import w3f from './brand-logo-light/w3f.svg'
import web3Builder from './brand-logo-light/web3-builder.svg'
import pkuba from './brand-logo-light/pku-blockchain.svg'
import thuba from './brand-logo-light/thuba.svg'
import fduba from './brand-logo-light/fudan-blockchain.svg'
import szuba from './brand-logo-light/szuba.svg'
import cuitbca from './brand-logo-light/cuitbca.svg'
import jluba from './brand-logo-light/jluba.svg'
import foresightNews from './brand-logo-light/foresight-news.svg'
import cointime from './brand-logo-light/cointime.svg'
import panews from './brand-logo-light/panews.svg'
import odaily from './brand-logo-light/odaily.svg'
import techFlow from './brand-logo-light/tech-flow.svg'
import jinseFinance from './brand-logo-light/jinse-finance.svg'
import quantum3labs from './brand-logo-light/quantum3labs.svg'

const data = [
  {
    name: 'Sponsorship',
    icons: [starknet]
  },
  {
    name: 'Strategic Partners',
    icons: [upchain, chainlink, theGraph, shurikenLabs, quantum3labs]
  },
  {
    name: 'Global Starknet Communities',
    icons: [starknetZh, starknetAstro, starknetAfrica, starknetEs, starknetFrance, starknetAsia]
  },
  {
    name: 'Co-building Communities',
    icons: [blockdev, akindo, contributionDao, coineasy, anyaxis, theWeb3, esolLabs, arnagon, degenHouse, rebase, hackQuest, lxdao, seedao, web3Builder, builderdao, w3f]
  },
  {
    name: 'University Blockchain Associations',
    icons: [pkuba, thuba, fduba, szuba, cuitbca, jluba]
  },
  {
    name: 'Media',
    icons: [foresightNews, cointime, panews, odaily, techFlow, jinseFinance]
  },
]

export default function PartnersSection() {
  return (
    <div className="partner" id="partner">
      <h1>Partners</h1>
      <div className="partner-content">
        {data.map((i, k) =>
          <div key={k}>
            <h6><span>—— ·&nbsp;</span>{i.name}<span>&nbsp;· ——</span></h6>
            <div className="partner-text">
              {i.icons.map((j, t) => <div key={`partner-icons-${i.name}-${t}`}><img src={j} alt="" /></div>)}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
