import './Header.css'
import Logo from './assets/light/logo.svg'
// import DiscoardLogo from './assets/discoard.svg'
// import XLogo from './assets/x.svg'

export default function Header() {
  return (
    <div className="header">
      <a href="/" className="header-logo">
        <img src={Logo} alt="" />
      </a>
      <div className="header-menu">
        <a href="#app" className="mh">
          Overview
        </a>
        {/* <a href="#introduction" className="mh">
          Introduction
        </a> */}
        <a href="#rewards" className="mh">
          Rewards
        </a>
        <a href="#judging" className="mh">
          Judging
        </a>
        <a href="#partner" className="mh">
          Partners
        </a>
      </div>
      <div className="header-right-buttons">
        {/* <a href="https://discord.com/invite/cbmteR7yRN" target="_blank" rel="noreferrer" >
          <img src={DiscoardLogo} alt="" />
        </a>
        <a href="https://twitter.com/OpenBuildxyz" target="_blank" rel="noreferrer">
          <img src={XLogo} alt="" />
        </a> */}
        <a href="https://build.bewater.xyz/en/campaigns/jK6g-Starknet-Hackathon-2024" target="_blank" rel="noreferrer" className="registration-button">
          Registration
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path d="M7.98652 3.66406L2.88818 8.76197L2.88817 8.76198C2.77532 8.87483 2.71191 9.0279 2.71191 9.1875C2.71191 9.3471 2.77532 9.50016 2.88817 9.61302C3.00102 9.72587 3.15409 9.78927 3.31369 9.78927C3.47329 9.78927 3.62635 9.72587 3.73921 9.61302L3.73921 9.61301L8.83711 4.51465V8.03906C8.83711 8.1986 8.90049 8.35161 9.01331 8.46443C9.12612 8.57724 9.27913 8.64062 9.43867 8.64062C9.59822 8.64062 9.75123 8.57724 9.86404 8.46443C9.97686 8.35161 10.0402 8.1986 10.0402 8.03906V3.0625C10.0402 2.90296 9.97686 2.74995 9.86404 2.63713C9.75123 2.52432 9.59822 2.46094 9.43867 2.46094H4.46212C4.30258 2.46094 4.14957 2.52432 4.03676 2.63713C3.92394 2.74995 3.86056 2.90296 3.86056 3.0625C3.86056 3.22204 3.92394 3.37505 4.03676 3.48787C4.14957 3.60068 4.30258 3.66406 4.46212 3.66406H7.98652Z" fill="white" stroke="white" strokeWidth="0.437499"/>
          </svg>
        </a>
        {/* <a href="https://openbuild.xyz" target="_blank" rel="noreferrer" className="go-team-button">
          Team Page
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path d="M7.98652 3.66406L2.88818 8.76197L2.88817 8.76198C2.77532 8.87483 2.71191 9.0279 2.71191 9.1875C2.71191 9.3471 2.77532 9.50016 2.88817 9.61302C3.00102 9.72587 3.15409 9.78927 3.31369 9.78927C3.47329 9.78927 3.62635 9.72587 3.73921 9.61302L3.73921 9.61301L8.83711 4.51465V8.03906C8.83711 8.1986 8.90049 8.35161 9.01331 8.46443C9.12612 8.57724 9.27913 8.64062 9.43867 8.64062C9.59822 8.64062 9.75123 8.57724 9.86404 8.46443C9.97686 8.35161 10.0402 8.1986 10.0402 8.03906V3.0625C10.0402 2.90296 9.97686 2.74995 9.86404 2.63713C9.75123 2.52432 9.59822 2.46094 9.43867 2.46094H4.46212C4.30258 2.46094 4.14957 2.52432 4.03676 2.63713C3.92394 2.74995 3.86056 2.90296 3.86056 3.0625C3.86056 3.22204 3.92394 3.37505 4.03676 3.48787C4.14957 3.60068 4.30258 3.66406 4.46212 3.66406H7.98652Z" fill="white" stroke="white" stroke-width="0.437499"/>
          </svg>
        </a> */}
      </div>
    </div>
  )
}
