import './style.css'
import BannerPic from './banner-bg.png'

export default function Onboard() {
  return (
    <div className="onboard">
      <div className="onboard-text">
        <h1>Starknet Hackathon 2024</h1>
        <p><span>Now - Oct 20th, 2024</span></p>
      </div>
      <img className="onboard-bg" src={BannerPic} alt="" />
    </div>
  )
}
