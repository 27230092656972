import Onboard from '../onboard'
import Timeline from '../timeline'

import './style.css'

export default function OverviewSection() {
  return (
    <>
      <Onboard />
      <div className="time-wrapper">
        <Timeline />
        <p>This hackathon unites the global Web3 Starknet community, fostering international tech collaboration. Developers passionate about Starknet or ZK tech are invited to join us and bring their ideas to life. We can’t wait to see the innovations that will strengthen the Starknet ecosystem!</p>
        <div className="time-button-group">
          <a href="https://twitter.com/OpenBuildxyz" target="_blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M13.6829 1.6875H16.1639L10.7437 7.8825L17.1202 16.3125H12.1274L8.21693 11.1997L3.74243 16.3125H1.25993L7.05743 9.68625L0.94043 1.6875H6.05993L9.59468 6.36075L13.6829 1.6875ZM12.8122 14.8275H14.1869L5.31293 3.0945H3.83768L12.8122 14.8275Z" fill="#D796FF"/>
            </svg>
            Follow Official Twitter
          </a>
          <a href="https://t.me/OpenBuildxyz/4" target="_blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" version="1.1"  width="24" height="24">
              <path d="M417.28 795.733333l11.946667-180.48 327.68-295.253333c14.506667-13.226667-2.986667-19.626667-22.186667-8.106667L330.24 567.466667 155.306667 512c-37.546667-10.666667-37.973333-36.693333 8.533333-55.466667l681.386667-262.826666c31.146667-14.08 61.013333 7.68 49.066666 55.466666l-116.053333 546.56c-8.106667 38.826667-31.573333 48.213333-64 30.293334L537.6 695.466667l-84.906667 82.346666c-9.813333 9.813333-17.92 17.92-35.413333 17.92z" fill="#D796FF" />
            </svg>
            Join our TG Channel
          </a>
        </div>
      </div>
    </>
  )
}
