import './style.css'

function PreliminaryReviewMain() {
  return (
    <div className="JudgingRule">
      <h2 className="JudgingRule-heading">Preliminary review 50%</h2>
      <p className="JudgingRule-description">Technical Dimension</p>
      <ul className="JudgingRule-rules">
        <li><span>Technical difficulty (20%): </span>The problem solved has a certain technical threshold and there is a certain breakthrough at the technical level;</li>
        <li><span>Project completion (20%): </span>The project is fully realized, not just the concept, and the Demo/POC display is complete;</li>
        <li><span>Standardization (10%): </span>The project is submitted within the specified time and the content is standardized.</li>
      </ul>
    </div>
  )
}

function PreliminaryReviewExtra() {
  return (
    <div className="judging-centent-textbox judging-centent-textbox-first">
      <p>The preliminary review will mainly review the project's code submission(the smart contract open source is require, at least), new features, test improvements, inheritance, deployment, etc. during the hackathon. Submitting a project with a clear history will help the reviewers understand the actual development work of the project.</p>
    </div>
  )
}

function FinalMain() {
  return (
    <div className="JudgingRule">
      <h2 className="JudgingRule-heading">Final 50%</h2>
      <p className="JudgingRule-description">Business Dimension</p>
      <ul className="JudgingRule-rules">
        <li><span>Commercial value (20%): </span>Can solve problems and be used in industry scenarios you know;</li>
        <li><span>Innovation (20%): </span>Novel ideas, breakthroughs, breaking conventional thinking;</li>
        <li><span>User experience (10%): </span>Including how intuitive and understandable the submitted solution is to potential users.</li>
      </ul>
    </div>
  )
}

function FinalExtra() {
  return (
    <div className="judging-centent-textbox">
      <p>The team participating in the demo is determined by the score of the technical preliminary review. After the demo is completed, the review can be adjusted based on the situation of the demo and the actual developed functions.</p>
    </div>
  )
}

export default function JudgingSection() {
  return (
    <div className="judging" id="judging">
      <h1>Judging Criteria</h1>
      <p>All entries will be scored in the following six dimensions:</p>
      <div className="judging-centent">
        <div className="judging-columns judging-columns-main">
          <PreliminaryReviewMain />
          <FinalMain />
        </div>
        <div className="judging-columns judging-columns-sub">
          <PreliminaryReviewExtra />
          <FinalExtra />
        </div>
      </div>
      <div className="judging-centent-mobile">
        <div>
          <PreliminaryReviewMain />
          <PreliminaryReviewExtra />
        </div>
        <div>
          <FinalMain />
          <FinalExtra />
        </div>
      </div>
    </div>
  )
}
